export const ALPHABET_SPACE_PATTERN = /^[A-Za-z ]+$/;

export const CLIENT_NAME_PATTERN = /^[A-Za-z-.'&() ]+$/;

export const NUMBER_ONLY_PATTERN = /^[0-9]+$/;

export const NUMBER_DOT_PATTERN = /^[0-9-.]+$/;

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_+=<>?])[A-Za-z\d!@#$%^&*()-_+=<>?]{8,32}$/;

export const ALPHABET_PATTERN = /^[A-Za-z]+$/;

export const ALPHABET_NUMBER_PATTERN = /^[A-Za-z0-9]+$/;

export const PAN_NUMBER_PATTERN = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

export const GST_PATTERN =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

export const NOT_ALLOW_SPACE = /^\S*$/;

export const ALPHABET_NOT_ALLOW_SPACE = /^[a-zA-Z][a-zA-Z\\s]+$/;

export const MOBILE_NO_PATTERN = /^\d{10}$/;

export const URL_PATTERN =
  /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

export const VEHICLE_PATTERN =
  /^[A-Z]{2}[\\ -]{0,1}[0-9]{2}[\\ ""-]{0,1}[A-Z]{1,2}[\\ -]{0,1}[0-9]{4}$/;

export const VEHICLE_SPACE = /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/;

export const DECIMAL_PATTERN = /^\d*\.?\d{0,3}$/;

export const DECIMAL_PATTERN_NEGATIVE_POSITIVE = /^-?\d*\.?\d{0,3}$/;

export const TWO_DECIMAL_PATTERN = /^\d*\.?\d{0,2}$/;

export const INDIAN_MOBILE_NO_PATTERN = /^(0?)[6789]{1}\d{9}$/;

export const IFFC_CODE_PATTERN = /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/;

export const ALPHABET_NUMBER_SPACE_PATTERN = /^[a-zA-Z 0-9]+$/;

export const NOT_ALLOWED_SPECIAL_CHARACTERS_PATTERN = /^[^'"`]+$/;

export const NAME_PATTERN = /^[a-zA-Z .]+$/;

export const ORIENTATION_PATTERN = /^[DF]+$/;

export const ALPHABET_NUMBER_SPACE_HYPHEN_PATTERNS = /^[a-zA-Z-0-9 ]+$/;

export const ALPHABET_NUMBER_SPACE_PATTERN_DOT_ALLOW = /^[a-zA-Z 0-9.]+$/;

export const ADDRESS_PATTERN = /^[a-zA-Z 0-9.:\\-\\/,'&()]+$/;

export const BENEFICEIARY_NAME = /^[a-zA-Z -,]+$/;

export const ACCOUNT_NUMBER_PATTERN = /^[a-zA-Z0-9]{8,35}$/;

export const ALPHABET_WITH_SPECIAL_CHAR = /^[a-zA-Z \-!,@#$%^&*()_+={}[\]|;:'",.<>/?]+$/

export const ONLY_ZERO_NUMBER = /^(?!0+$).+/;

export const DECIMAL_PATTERN_2_DIGITS = /^\d+(\.\d{0,2})?$/