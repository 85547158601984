import React, { useEffect, useState } from 'react';
import SideBar from '../components/side-bar';
import Header from '../components/header';
import Footer from '../components/footer';
import { IsStringNullEmptyOrUndefined } from '../utils/null-check';
import { Navigate } from 'react-router-dom';
import { AssignedModuleData, UserInfoEntity } from '../interface/sign-in';
import { ActionEntityEnum, StorageKeyEnum } from '../utils/enum';
import { allFeaturedModules } from '../utils/constants';

declare var $: any;

function AuthAppLayout({ page, featuredPage }: { page: JSX.Element; featuredPage: string }) {

    const [isauthorized, setIsauthorized] = useState<number>(-1);

    useEffect(() => {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }, []);

    useEffect(() => {
        if (featuredPage && !IsStringNullEmptyOrUndefined(featuredPage)) {
            const userStr: string | null = localStorage.getItem(StorageKeyEnum.JB_GLASS_USER_INFO);

            if (userStr && !IsStringNullEmptyOrUndefined(userStr)) {
                const userInfo: UserInfoEntity = JSON.parse(userStr);
                const modules: AssignedModuleData[] = userInfo.assignedModule;

                if (featuredPage === allFeaturedModules.dashboard || allFeaturedModules.roleManagement) {
                    setIsauthorized(1);
                } else {
                    const index = modules.findIndex(e => e.moduleName === featuredPage && e.isActive && e.action === ActionEntityEnum.LIST);
                    setIsauthorized(index !== -1 ? 1 : 403);
                }
            } else {
                setIsauthorized(0);
            }
        }
    }, [featuredPage]);

    return (
        <>
            {
                isauthorized === 1 &&
                <div className="container-scroller">
                    <Header />
                    <div className="container-fluid page-body-wrapper">
                        <SideBar />
                        <div className="main-panel">
                            {page}
                            <Footer />
                        </div>
                    </div>
                </div>
            }
            {
                isauthorized === 403 &&
                <Navigate replace to={'/forbidden'} />
            }

            {
                isauthorized === 0 &&
                <Navigate replace to={'/'} />
            }
        </>
    );
}

export default AuthAppLayout;